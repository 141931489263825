import { ResponsePromise } from 'types/api';
import { Post } from 'types/posts';
import api from './api';

const SCOPE = 'posts';

export const getPosts = (): ResponsePromise => {
  return api.get(SCOPE);
};

export const getPost = (id: number): ResponsePromise => {
  return api.get(`${SCOPE}/${id}`);
};

type CreateColumn = 'title' | 'body' | 'publish';
export const createPost = (data: Pick<Post, CreateColumn>): ResponsePromise => {
  return api.post(`${SCOPE}`, { json: data });
};

type UpdateColumn = 'id' | CreateColumn;
export const updatePost = (id: number, data: Pick<Post, UpdateColumn>): ResponsePromise => {
  return api.put(`${SCOPE}/${id}`, { json: data });
};

export const deletePost = (id: number): ResponsePromise => {
  return api.delete(`${SCOPE}/${id}`);
};
