import React from 'react';
import { useForm } from 'react-hook-form';
import { ProgressScreen } from 'components/molecules';
import { PostForm, PostFormValue } from 'components/organisms/forms/postForm';
import { updatePost } from 'requests/internal/posts';
import { Post } from 'types/posts';

type Props = {
  post: Post | null;
};

export const UpdatePostForm: React.VFC<Props> = ({ post }) => {
  if (post == null) return <ProgressScreen isOpen={post == null} />;

  const { handleSubmit, control, setValue, watch } = useForm<PostFormValue>({
    mode: 'onBlur',
    defaultValues: {
      title: post.title,
      body: post.body,
    },
  });
  const title = watch('title', '');
  const body = watch('body', '');

  const handleChange = (currentValue: string) => {
    setValue('body', currentValue);
  };
  const onSubmit = (value: PostFormValue) => {
    if (post == null) return;
    return updatePost(post.id, { id: post.id, publish: true, ...value });
  };

  return (
    <PostForm
      control={control}
      currentValues={{ title: title, body: body }}
      handleChange={handleChange}
      handleSubmit={handleSubmit(onSubmit)}
    />
  );
};
