import React from 'react';
import { Container, makeStyles } from '@material-ui/core';
import ReactDOM from 'react-dom';
import { QueryProvider } from 'providers/queryProvider';
import { SignedStatusProvider } from 'providers/signedStatusProvider';
import { ThemeProvider } from 'providers/themeProvider';
import { Router } from 'routes';
import 'constants/fonts';

const useStyles = makeStyles(() => ({
  main: {
    width: '1024px',
  },
}));

export const App: React.FC = () => {
  const classes = useStyles();
  return (
    <Container className={classes.main}>
      <ThemeProvider>
        <QueryProvider>
          <SignedStatusProvider>
            <Router />
          </SignedStatusProvider>
        </QueryProvider>
      </ThemeProvider>
    </Container>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
