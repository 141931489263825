import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { TokenStream } from 'prismjs';
import { CodeChunk } from 'components/atoms/codings/codeChunk';

type Props = {
  chunksList: Array<TokenStream>;
  className?: string;
};

const useStyles = makeStyles(() => ({
  disabledHighLight: {
    padding: '5px',
  },
}));

export const LangCode: React.VFC<Props> = ({ chunksList, className }) => {
  const classes = useStyles();
  return (
    <code className={className ? className : clsx(['language-unknown', classes.disabledHighLight])}>
      {chunksList.map((chunks, idx) => (
        <CodeChunk contents={chunks} key={idx} />
      ))}
    </code>
  );
};
