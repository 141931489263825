import React from 'react';
import { useQuery } from 'react-query';
import { ProgressScreen } from 'components/molecules';
import { UpdatePostForm } from 'components/templates/posts/updatePostForm';
import { toJson } from 'requests/internal/api';
import { getPost } from 'requests/internal/posts';
import { Post } from 'types/posts';

type Prop = {
  postId: number;
};

export const UpdatePost: React.VFC<Prop> = ({ postId }) => {
  const { data: post = null } = useQuery(['posts', postId], () => toJson<Post>(getPost(postId)));

  if (post == null) return <ProgressScreen isOpen={post == null} />;
  return <UpdatePostForm post={post} />;
};
