import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { Redirect } from 'react-router';
import { SimpleForm, SimpleFormValue } from 'components/organisms/forms/simpleForm';
import { isOk } from 'requests/internal/api';
import { createChangeLog } from 'requests/internal/change_logs';

export const NewChangeLogForm: React.VFC = () => {
  const { handleSubmit, control } = useForm<SimpleFormValue>({
    mode: 'onBlur',
    defaultValues: {
      title: '',
      description: '',
    },
  });
  const [error, setError] = useState('');
  const [created, setCreated] = useState<boolean>(false);

  const onSubmit = (data: SimpleFormValue) => {
    return isOk(createChangeLog(data))
      .then(() => {
        setCreated(true);
      })
      .catch(() => {
        setError('Change Log create Failed');
      });
  };

  if (created) return <Redirect to={'/'} />;
  return (
    <SimpleForm
      submitLabel={'Create'}
      control={control}
      error={error}
      handleSubmit={handleSubmit(onSubmit)}
    />
  );
};
