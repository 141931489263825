import { Action } from 'types/action';

export const signinActionKey = 'SIGN_IN' as const;
type signinPayloads = {
  signed: boolean;
};
export type SignInAction = Action<typeof signinActionKey, signinPayloads>;
export const createSignInAction = (payloads: signinPayloads): SignInAction => ({
  type: signinActionKey,
  payloads: { signed: payloads.signed },
});

export const signoutActionKey = 'SIGN_OUT' as const;
type signoutPayloads = {
  signed: boolean;
};
export type SignOutAction = Action<typeof signoutActionKey, signoutPayloads>;
export const createSignOutAction = (payloads: signoutPayloads): SignOutAction => ({
  type: signoutActionKey,
  payloads: { signed: payloads.signed },
});

export type AuthorizeAction = SignInAction | SignOutAction;
