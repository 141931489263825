import React from 'react';
import { useParams } from 'react-router';
import { ErrorFallback, Meta } from 'components/atoms';
import { Boundary, ButtonLink } from 'components/molecules';
import { ShowPost } from 'components/templates';
import { useSignedStatus } from 'providers/signedStatusProvider';

type ContextProp = {
  postId?: string;
};

export const Show: React.VFC = () => {
  const { postId } = useParams<ContextProp>();
  const { signed } = useSignedStatus();

  if (postId == undefined)
    return <ErrorFallback error={new Error('不明なパラメータが指定されました')} />;

  const numId = parseInt(postId);
  return (
    <>
      <Meta variant='title' value={'Notes'} />
      <Boundary>
        {signed && (
          <div style={{ marginBottom: '10px' }}>
            <ButtonLink path={`/notes/${postId}/edit`} label={'+ Edit Post'} />
          </div>
        )}
        <ShowPost postId={numId} />
      </Boundary>
    </>
  );
};
