import React from 'react';
import { useForm } from 'react-hook-form';
import { PostForm, PostFormValue } from 'components/organisms/forms/postForm';
import { createPost } from 'requests/internal/posts';

export const NewPostForm: React.VFC = () => {
  const { handleSubmit, control, setValue, watch } = useForm<PostFormValue>({
    mode: 'onBlur',
    defaultValues: {
      title: '',
      body: '',
    },
  });
  const title = watch('title', '');
  const body = watch('body', '');


  const handleChange = (currentValue: string) => {
    if (currentValue != body) setValue('body', currentValue);
  };
  const onSubmit = (value: PostFormValue) => {
    return createPost({ publish: true, ...value });
  };

  return (
    <PostForm
      control={control}
      currentValues={{ title: title, body: body }}
      handleChange={handleChange}
      handleSubmit={handleSubmit(onSubmit)}
    />
  );
};
