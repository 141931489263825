import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import { Edit } from 'components/pages/changeLogs/edit';
import { New } from 'components/pages/changeLogs/new';

export const Root: React.VFC = () => {
  const routing = useRouteMatch();
  return (
    <>
      <Switch>
        <Route path={`${routing.path}/new`} exact={true}>
          <New />
        </Route>
        <Route path={`${routing.path}/:logId/edit`} exact={true}>
          <Edit />
        </Route>
      </Switch>
    </>
  )
}
