import React from 'react';
import { useQuery } from 'react-query';
import { HoldableList } from 'components/organisms/lists/holdableList';
import { toJson } from 'requests/internal/api';
import { getChangeLogs } from 'requests/internal/change_logs';
import { ChangeLog } from 'types/changeLog';
import { picker } from 'utils/picker';
import { yearFormat } from 'utils/times';

export const ListChangeLog: React.VFC = () => {
  const { data: logs = [] } = useQuery(['changeLogs'], () =>
    toJson<Array<ChangeLog>>(getChangeLogs()),
  );

  return (
    <HoldableList
      items={logs}
      keyFunc={picker('id')}
      titleFunc={item => yearFormat(item.changed_at)}
      summaryFunc={picker('title')}
      detailFunc={picker('description')}
      emptyText={'更新履歴はありません'}
    />
  );
};
