import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';

import { Redirect } from 'react-router-dom';
import { createSignInAction } from 'actions/authorize';
import { Container, Typography } from 'components/atoms';
import { LockedAvatar } from 'components/molecules';
import { SignForm, SignFormValue } from 'components/organisms/forms/signForm';
import { useSignedDispatch, useSignedStatus } from 'providers/signedStatusProvider';
import { isOk } from 'requests/internal/api';
import { signIn } from 'requests/internal/authorize';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export const SignInForm: React.VFC = () => {
  const classes = useStyles();
  const { signed } = useSignedStatus();
  const dispatch = useSignedDispatch();
  const { handleSubmit, control } = useForm<SignFormValue>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const [error, setError] = useState('');

  const onSubmit = (data: SignFormValue) => {
    isOk(signIn(data))
      .then((flag) => {
        if (flag) {
          dispatch(createSignInAction({signed: true}));
        } else {
          setError('Sign in Failed: invalid Email / Password')
        }
      })
      .catch(() => {
        setError('Sign in failed: Something wrong');
      });
  };

  if (signed) return <Redirect to='/'></Redirect>;
  return (
    <Container className={clsx(classes.paper)} maxWidth='xs'>
      <LockedAvatar />
      <Typography variant='h5'>Sign in</Typography>
      <SignForm
        submitLabel={'Sign In'}
        control={control}
        error={error}
        handleSubmit={handleSubmit(onSubmit)}
      />
    </Container>
  );
};
