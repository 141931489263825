import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Redirect } from 'react-router';
import { SimpleForm, SimpleFormValue } from 'components/organisms/forms/simpleForm';
import { isOk } from 'requests/internal/api';
import { createChangeLog } from 'requests/internal/change_logs';
import { ChangeLog } from 'types/changeLog';

type Props = {
  changeLog: ChangeLog;
};

export const UpdateChangeLogForm: React.VFC<Props> = ({ changeLog }) => {
  const { handleSubmit, control, setValue } = useForm<SimpleFormValue>({
    mode: 'onBlur',
    defaultValues: {
      title: '',
      description: '',
    },
  });
  const [error, setError] = useState('');
  const [updated, setUpdated] = useState<boolean>(false);

  useEffect(() => {
    if (changeLog) {
      setValue('title', changeLog.title);
      setValue('description', changeLog.description);
    }
  }, [changeLog]);

  const onSubmit = (data: SimpleFormValue) => {
    isOk(createChangeLog(data))
      .then(() => {
        setUpdated(true);
      })
      .catch(() => {
        setError('Change Log create Failed');
      });
  };

  if (updated) return <Redirect to={'/'} />;
  return (
    <SimpleForm
      submitLabel={'Update'}
      control={control}
      error={error}
      handleSubmit={handleSubmit(onSubmit)}
    />
  );
};
