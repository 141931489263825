import React from 'react';
import { ErrorFallback, Meta } from 'components/atoms';
import { Boundary } from 'components/molecules';
import { NewPost } from 'components/templates';
import { useSignedStatus } from 'providers/signedStatusProvider';

export const New: React.VFC = () => {

  const { signed } = useSignedStatus();

  if (!signed) {
    return <ErrorFallback error={new Error('Forbidden')} />;
  }

  return (
    <>
      <Meta variant='title' value={'Post'} />
      <Boundary>
        <NewPost />
      </Boundary>
    </>
  );
};
