import React, { MouseEventHandler } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Control, Controller } from 'react-hook-form';
import { Alert, Button, TextField } from 'components/atoms';
import { isString } from 'types/determine/primitive';

export type SimpleFormValue = {
  title: string;
  description: string;
};

type Props = {
  submitLabel: string;
  control: Control<SimpleFormValue>;
  error?: string;
  handleSubmit: MouseEventHandler<HTMLButtonElement>;
};

const useStyles = makeStyles(theme => ({
  form: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  submit: {
    marginTop: theme.spacing(2),
  },
}));

export const SimpleForm: React.VFC<Props> = ({ submitLabel, control, error, handleSubmit }) => {
  const classes = useStyles();
  return (
    <form className={classes.form}>
      {isString(error) && error.length != 0 && <Alert severity={'error'}>{error}</Alert>}
      <Controller
        name={'title'}
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            variant={'outlined'}
            margin={'normal'}
            label={'Title'}
            fullWidth
            required
          />
        )}
      />
      <Controller
        name={'description'}
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            variant={'outlined'}
            margin={'normal'}
            label={'Description'}
            fullWidth
            multiline
            required
          />
        )}
      />
      <Button
        type={'button'}
        variant={'contained'}
        color={'primary'}
        onClick={handleSubmit}
        className={clsx(classes.submit)}
        fullWidth
      >
        {submitLabel}
      </Button>
    </form>
  );
};
