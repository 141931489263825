import React from 'react';
import { useQuery } from 'react-query';
import { CardList } from 'components/organisms/lists/cardList';
import { toJson } from 'requests/internal/api';
import { getPosts } from 'requests/internal/posts';
import { Post } from 'types/posts';
import { picker } from 'utils/picker';

export const ListPost: React.VFC = () => {
  const { data: posts = [] } = useQuery(['posts'], () => toJson<Array<Post>>(getPosts()));

  return (
    <CardList 
      items={posts}
      keyFunc={picker('id')}
      titleFunc={picker('title')}
      bodyFunc={picker('body')}
      linkFunc={(post) => `/notes/${post.id}`}
      createTimeFunc={picker('created_at')}
      updateTimeFunc={picker('updated_at')}    
      emptyText={'準備中...'}
    />
  );
};
