import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import { Edit } from 'components/pages/posts/edit';
import { Index } from 'components/pages/posts/index';
import { New } from 'components/pages/posts/new';
import { Show } from 'components/pages/posts/show';

export const Root: React.VFC = () => {
  const routing = useRouteMatch();
  return (
    <>
      <Switch>
        <Route path={routing.path} exact={true}>
          <Index />
        </Route>
        <Route path={`${routing.path}/new`} exact={true}>
          <New />
        </Route>
        <Route path={`${routing.path}/:postId/edit`} exact={true}>
          <Edit />
        </Route>
        <Route path={`${routing.path}/:postId`} exact={true}>
          <Show />
        </Route>
      </Switch>
    </>
  )
}
