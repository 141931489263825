import React from 'react';
import { Meta } from 'components/atoms';
import { BasicBlock, Boundary, ButtonLink } from 'components/molecules';
import { ListPost } from 'components/templates';
import { useSignedStatus } from 'providers/signedStatusProvider';

export const Index: React.VFC = () => {
  const { signed } = useSignedStatus();
  return (
    <>
      <Meta variant='title' value={'Notes'} />
      <BasicBlock
        title={'Notes'}
        body={
          <Boundary>
            {signed && <div style={{marginBottom: '10px'}}><ButtonLink path={'/notes/new'} label={'+ New Post'} /></div>}
            <ListPost />
          </Boundary>
        }
      />
    </>
  );
};
