import React from 'react';
import clsx from 'clsx';

type Props = {
  children: React.ReactNode[];
  className?: string;
};

const PRISM_OPTIONS = ['line-numbers', 'show-language'];

export const PrismPre: React.VFC<Props> = ({ children, className }) => {
  return (
    <pre className={clsx(PRISM_OPTIONS, { 'language-unknown': className == undefined }, className)}>
      {children}
    </pre>
  );
};
