import { ResponsePromise } from 'types/api';
import { User } from 'types/user';
import api from './api';

const SCOPE = 'auths';

export const signStatus = (): ResponsePromise => {
  return api.get(SCOPE);
};

type Column = 'email' | 'password';
export const signIn = (param: Pick<User, Column>): ResponsePromise => {
  return api.post(SCOPE, { json: param });
};

export const signOut = (): ResponsePromise => {
  return api.delete(SCOPE);
};
