import ky from 'ky';
import { ResponsePromise } from 'types/api';

const api = ky.create({
  prefixUrl: API_ROOT,
  credentials: 'include',
  throwHttpErrors: false,
});

export default api;

export const isOk = async (response: ResponsePromise): Promise<boolean> => {
  return (await response).ok;
};

export const toJson = async <T>(response: ResponsePromise): Promise<T> => {
  return await response.json();
};
