import React, { Fragment } from 'react';
import { HoldableListItem } from 'components/organisms/lists/holdableListItem';

type Props<T> = {
  items: Array<T>;
  keyFunc: (item: T) => string | number;
  titleFunc: (item: T) => string;
  summaryFunc: (item: T) => string;
  detailFunc: (item: T) => string;
  emptyText?: string;
};

export const HoldableList = <T,>({
  items,
  keyFunc,
  titleFunc,
  summaryFunc,
  detailFunc,
  emptyText,
}: Props<T>): React.ReactElement => {
  if (items.length == 0) return emptyText ? <Fragment /> : <div>{emptyText}</div>;
  return (
    <>
      {items.map(item => (
        <HoldableListItem
          key={keyFunc(item)}
          title={titleFunc(item)}
          summary={summaryFunc(item)}
          details={detailFunc(item)}
        />
      ))}
    </>
  );
};
