import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

type Props = {
  children: React.ReactNode[];
  className?: string;
};

const useStyles = makeStyles(() => ({
  disabledHighLight: {
    padding: '5px',
  },
}));

export const NoLangCode: React.VFC<Props> = ({ children, className }) => {
  const classes = useStyles();
  return (
    <code className={className ? className : clsx(['language-unknown', classes.disabledHighLight])}>
      {children}
    </code>
  );
};
