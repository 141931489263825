import React, { MouseEventHandler, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import { Control, Controller } from 'react-hook-form';
import { Box, Button, TextField } from 'components/atoms';
import { BasicBlock } from 'components/molecules';
import { Editor, EditorRef } from 'components/organisms/forms/editor';
import { Markdown } from 'components/organisms/markdown';

export type PostFormValue = {
  title: string;
  body: string;
};

type Props = {
  control: Control<PostFormValue>;
  currentValues: PostFormValue;
  handleChange: (currentValue: string) => void;
  handleSubmit: MouseEventHandler<HTMLButtonElement>;
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '2048px',
    marginLeft: '-512px',
  },
  segments: {
    maxWidth: '1024px',
  },
  submit: {
    marginTop: theme.spacing(2),
  },
}));

export const PostForm: React.VFC<Props> = ({
  control,
  currentValues,
  handleChange,
  handleSubmit,
}) => {
  const monaco = useRef<EditorRef>(null);
  const classes = useStyles();
  return (
    <form className={classes.root}>
      <Box display={'flex'}>
        <Box flexGrow={1} p={1} className={classes.segments}>
          {/** Form Input */}
          <Controller
            name={'title'}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant={'outlined'}
                margin={'normal'}
                label={'Title'}
                required
                fullWidth
              />
            )}
          />
          <Controller
            name={'body'}
            control={control}
            render={({ field }) => <input {...field} hidden />}
          />
          <Editor
            defaultValue={currentValues.body}
            language={'markdown'}
            theme={'vs-dark'}
            ref={monaco}
            onChange={handleChange}
          />
        </Box>
        <Box flexGrow={1} p={1} className={classes.segments}>
          {/** Previews */}
          <BasicBlock
            title={currentValues.title}
            body={<Markdown markdown={currentValues.body} />}
          />
        </Box>
      </Box>
      <Button
        type={'button'}
        variant={'contained'}
        color={'primary'}
        onClick={handleSubmit}
        fullWidth
      >
        Save
      </Button>
    </form>
  );
};
