import React from 'react';
import { useQuery } from 'react-query';
import { BasicBlock, ProgressScreen } from 'components/molecules';
import { Markdown } from 'components/organisms/markdown';
import { toJson } from 'requests/internal/api';
import { getPost } from 'requests/internal/posts';
import { Post } from 'types/posts';

type Prop = {
  postId: number;
};

export const ShowPost: React.VFC<Prop> = ({ postId }) => {
  const { data: post = null } = useQuery(['posts', postId], () => toJson<Post>(getPost(postId)));

  if (post == null) return <ProgressScreen isOpen={post == null} />;
  return <BasicBlock title={post.title} body={<Markdown markdown={post.body} />} />;
};
