import React from 'react';
import { useParams } from 'react-router';
import { ErrorFallback } from 'components/atoms';
import { BasicBlock } from 'components/molecules';
import { UpdateChangeLog } from 'components/templates/changeLogs/updateChangeLog';
import { useSignedStatus } from 'providers/signedStatusProvider';

type ContextProp = {
  logId?: string;
};

export const Edit: React.VFC = () => {
  const { signed } = useSignedStatus();

  if (!signed) {
    return <ErrorFallback error={new Error('Forbidden')} />;
  }

  const { logId } = useParams<ContextProp>();

  if (logId == undefined)
    return <ErrorFallback error={new Error('不明なパラメータが指定されました')} />;

  const numId = parseInt(logId);

  return <BasicBlock title={'UPDATE Change Log'} body={<UpdateChangeLog logId={numId} />} />;
};
