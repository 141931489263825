import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core';
import {
  Card,
  CardContent,
  CardHeader,
  CardActionArea,
  InternalLink,
  Typography,
} from 'components/atoms';
import { TimeStamp } from 'components/molecules/icons/timeStamp';
import { yearFormat } from 'utils/times';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    height: '130px',
    margin: '10px 0'
  },
  timeArea: {
    padding: theme.spacing(0.5),
    display: 'flex',
    justifyContent: 'end',
  },
  timeSep: {
    padding: 3,
    lineHeight: 2.0,
  },
  noDecorate: {
    textDecoration: 'none !important',
    color: theme.palette.text.primary,
  },
}));

type Props = {
  title: string;
  body: string;
  createdAt?: string;
  updatedAt?: string;
  link?: string;
};

export const CardListItem: React.VFC<Props> = ({ title, createdAt, updatedAt, link }) => {
  const classes = useStyles();

  const Linker = (props: { children: ReactNode }) =>
    link ? (
      <InternalLink to={link} className={classes.noDecorate}>
        {props.children}
      </InternalLink>
    ) : (
      <>{props.children}</>
    );
  return (
    <Card className={classes.card} variant={'outlined'}>
      <Linker>
        <CardActionArea>
          <CardHeader title={<Typography variant={'h6'}>{title}</Typography>} />
          <CardContent>
            <div className={classes.timeArea}>
              <TimeStamp label={'CREATED'} time={createdAt} formatter={yearFormat} />
              <span className={classes.timeSep}> / </span>
              <TimeStamp label={'UPDATED'} time={updatedAt} formatter={yearFormat} />
            </div>
          </CardContent>
        </CardActionArea>
      </Linker>
    </Card>
  );
};
