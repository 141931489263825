import React, { createContext, Reducer, useContext, useEffect, useReducer } from 'react';
import {
  AuthorizeAction,
  createSignInAction,
  createSignOutAction,
  signinActionKey,
  signoutActionKey,
} from 'actions/authorize';
import { isOk } from 'requests/internal/api';
import { signStatus } from 'requests/internal/authorize';
import { User } from 'types/user';

type SignedStatus = {
  signed: boolean;
  currentUser: User | null;
};

const reducer: Reducer<SignedStatus, AuthorizeAction> = (state, action) => {
  switch (action.type) {
    case signinActionKey:
      return { ...state, signed: action.payloads.signed };
    case signoutActionKey:
      return { ...state, signed: action.payloads.signed };
  }
};
const initialStatus = {
  signed: false,
  currentUser: null,
};

const SignedContext = createContext<SignedStatus>(initialStatus);

const initialDispatch: React.Dispatch<AuthorizeAction> = () => {
  return;
};
const SignedDispatchContext = createContext<React.Dispatch<AuthorizeAction>>(initialDispatch);

export const SignedStatusProvider: React.FC = ({ children }) => {
  const [status, dispatch] = useReducer(reducer, initialStatus);
  useEffect(() => {
    isOk(signStatus())
      .then(flag => dispatch(createSignInAction({ signed: flag })))
      .catch(() => {
        dispatch(createSignOutAction({ signed: false }));
      });
  }, []);

  return (
    <SignedContext.Provider value={status}>
      <SignedDispatchContext.Provider value={dispatch}>{children}</SignedDispatchContext.Provider>
    </SignedContext.Provider>
  );
};

export const useSignedStatus = (): SignedStatus => useContext(SignedContext);
export const useSignedDispatch = (): React.Dispatch<AuthorizeAction> =>
  useContext(SignedDispatchContext);
