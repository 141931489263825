import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { createSignOutAction } from 'actions/authorize';
import { ButtonForm } from 'components/organisms/forms/buttonForm';
import { useSignedDispatch, useSignedStatus } from 'providers/signedStatusProvider';
import { isOk } from 'requests/internal/api';
import { signOut } from 'requests/internal/authorize';

export const SignOutForm: React.VFC = () => {
  const { signed } = useSignedStatus();
  const dispatch = useSignedDispatch();

  const [error, setError] = useState<string>('');

  const onSubmit = () => {
    return isOk(signOut())
      .then(flag => {
        if (flag) {
          dispatch(createSignOutAction({ signed: false }));
        } else {
          setError('Sign Out Failed: Already Sign Outed');
        }
      })
      .catch(() => {
        setError('Sign Out failed: Something wrong');
      });
  };
  if (!signed) return <Redirect to='/'></Redirect>;
  return (
    <ButtonForm
      submitLabel={'Sign Out'}
      description={'Sign Out'}
      handleSubmit={onSubmit}
      error={error}
    />
  );
};
