import React from 'react';
import { LangCode, NoLangCode } from 'components/atoms';
import { usePrism } from 'hooks/prism';
import { isString } from 'types/determine/primitive';

type Props = {
  children: React.ReactNode[];
  className?: string;
};

export const PrismCode: React.VFC<Props> = ({ children, className }) => {
  const { tokenize, detectLanguage } = usePrism();
  const lang = detectLanguage(className);
  if (lang == null) {
    return <NoLangCode className={className}>{children}</NoLangCode>;
  } else {
    const chunksList = children.filter(isString).map(c => tokenize(lang, c));
    return <LangCode chunksList={chunksList} className={className} />;
  }
};
