export const isString = (arg: unknown): arg is string => typeof arg == 'string';
export const isNumber = (arg: unknown): arg is number => typeof arg == 'number';
export const isBoolean = (arg: unknown): arg is boolean => typeof arg == 'boolean';

export const isArray = (arg: unknown): arg is Array<unknown> => Array.isArray(arg);
export const isDate = (arg: unknown): arg is Date => {
  if (isString(arg) || isNumber(arg)) {
    return !isNaN(new Date(arg).getTime());
  }
  if (arg instanceof Date) {
    return !isNaN(arg.getTime());
  }
  return false;
};

