import { ResponsePromise } from 'types/api';
import { ChangeLog } from 'types/changeLog';
import api from './api';

const SCOPE = 'change_logs';

export const getChangeLogs = (): ResponsePromise => {
  return api.get(SCOPE);
};

export const getChangeLog = (id: number): ResponsePromise => {
  return api.get(`${SCOPE}/${id}`);
};

type CreateColumn = 'title' | 'description';
export const createChangeLog = (data: Pick<ChangeLog, CreateColumn>): ResponsePromise => {
  return api.post(SCOPE, { json: data });
};

type UpdateColumn = 'id' | CreateColumn;
export const updateChangeLog = (id: number, data: UpdateColumn): ResponsePromise => {
  return api.put(`${SCOPE}/${id}`, { json: data });
};

export const deleteChangeLog = (id: number): ResponsePromise => {
  return api.delete(`${SCOPE}/${id}`);
};
