import React from 'react';
import { useParams } from 'react-router';
import { ErrorFallback, Meta } from 'components/atoms';
import { Boundary } from 'components/molecules';
import { UpdatePost } from 'components/templates';
import { useSignedStatus } from 'providers/signedStatusProvider';

type ContextProp = {
  postId?: string;
};

export const Edit: React.VFC = () => {
  const { signed } = useSignedStatus();

  if (!signed) {
    return <ErrorFallback error={new Error('Forbidden')} />;
  }

  const { postId } = useParams<ContextProp>();

  if (postId == undefined)
    return <ErrorFallback error={new Error('不明なパラメータが指定されました')} />;

  const numId = parseInt(postId);
  return (
    <>
      <Meta variant='title' value={'Post'} />
      <Boundary>
        <UpdatePost postId={numId} />
      </Boundary>
    </>
  );
};
