import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  DefinitionItem,
  DefinitionList,
  Typography,
} from 'components/atoms';
import { ExpandMore } from 'components/atoms/icons';

type Props = {
  title: string;
  summary: string;
  details: string;
};

export const HoldableListItem: React.VFC<Props> = ({ title, summary, details }) => {
  return (
    <DefinitionList>
      <DefinitionItem
        term={<Typography variant={'body2'}>{title}</Typography>}
        description={
          <Accordion elevation={0}>
            <AccordionSummary expandIcon={<ExpandMore />}>{summary}</AccordionSummary>
            <AccordionDetails>{details}</AccordionDetails>
          </Accordion>
        }
      ></DefinitionItem>
    </DefinitionList>
  );
};
