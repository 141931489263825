import React from 'react';
import { ErrorFallback, Meta } from 'components/atoms';
import { BasicBlock } from 'components/molecules';
import { NewChangeLog } from 'components/templates/changeLogs/newChangeLog';
import { useSignedStatus } from 'providers/signedStatusProvider';

export const New: React.VFC = () => {
  console.log('new log!')
  const { signed } = useSignedStatus();

  if (!signed) {
    return <ErrorFallback error={new Error('Forbidden')} />;
  }

  return (
    <>
      <Meta variant='title' value={'ChangeLog'} />
      <BasicBlock title={'CREATE Change Log'} body={<NewChangeLog />} />
    </>
  );
};
