import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Box, Toolbar } from 'components/atoms';
import { LockOutlined, ExitToAppOutlined } from 'components/atoms/icons';
import { ButtonLink, Logo } from 'components/molecules';
import { useSignedStatus } from 'providers/signedStatusProvider';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    padding: 0,
  },
}));

export const TitleHeader: React.FC = () => {
  const { signed } = useSignedStatus();
  const classes = useStyles();

  return (
    <Toolbar className={clsx(classes.root)}>
      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
        <Box>
          <Logo />
        </Box>
        <Box>
          <ButtonLink
            path={signed ? '/signout' : '/signin'}
            label={signed ? <ExitToAppOutlined /> : <LockOutlined color={'disabled'} />}
          />
        </Box>
      </Box>
    </Toolbar>
  );
};
