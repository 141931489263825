import React from 'react';
import clsx from 'clsx';
import { isArray, isString } from 'types/determine/primitive';

type Props = {
  contents: Prism.TokenStream;
};

export const CodeChunk: React.VFC<Props> = ({ contents }) => {
  if (isString(contents)) return <StringChunk contents={contents} />;
  if (isArray(contents)) return <ArrayChunk contents={contents} />;
  return <TokenChunk contents={contents} />;
};

const StringChunk: React.VFC<{ contents: string }> = ({ contents }) => <>{contents}</>;

const ArrayChunk: React.VFC<{ contents: Array<string | Prism.Token> }> = ({ contents }) => (
  <>
    {contents.map((chunk, idx) => (
      <CodeChunk contents={chunk} key={idx} />
    ))}
  </>
);

const TokenChunk: React.VFC<{ contents: Prism.Token }> = ({ contents }) => (
  <span className={clsx('token', contents.type)}>
    <CodeChunk contents={contents.content} />
  </span>
);
