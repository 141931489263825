import React from 'react';
import { Meta } from 'components/atoms';
import { BasicBlock, ButtonLink } from 'components/molecules';
import { Boundary } from 'components/molecules/fallback/boundary';
import { ListChangeLog, Profile } from 'components/templates';
import { useSignedStatus } from 'providers/signedStatusProvider';

export const Top: React.VFC = () => {
  const { signed } = useSignedStatus();

  return (
    <>
      <Meta variant='title' value={'Top'} />
      <Profile />
      <BasicBlock
        title={'Change Log'}
        id={'change_log'}
        body={
          <Boundary>
            {signed && (
              <div style={{ marginBottom: '10px' }}>
                <ButtonLink path={'changeLogs/new'} label={'+ New Change Log'} />
              </div>
            )}
            <ListChangeLog />
          </Boundary>
        }
      />
    </>
  );
};
