import React from 'react';
import { useQuery } from 'react-query';
import { ProgressScreen } from 'components/molecules';
import { UpdateChangeLogForm } from 'components/templates/changeLogs/updateChangeLogForm';
import { toJson } from 'requests/internal/api';
import { getChangeLog } from 'requests/internal/change_logs';
import { ChangeLog } from 'types/changeLog';

type Prop = {
  logId: number;
};

export const UpdateChangeLog: React.VFC<Prop> = ({ logId }) => {
  const { data: log = null } = useQuery(['changeLogs', logId], () => toJson<ChangeLog>(getChangeLog(logId)));

  if (log == null) return <ProgressScreen isOpen={log == null} />;
  return <UpdateChangeLogForm changeLog={log} />;
};
