import React from 'react';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import { Typography } from 'components/atoms';
import { AccessTime } from 'components/atoms/icons';

type Props = {
  label: string;
  time: moment.MomentInput;
  formatter: (t: moment.MomentInput) => string;
};

const useStyles = makeStyles(() => ({
  timeRow: {
    padding: 0,
    margin: 0,
    lineHeight: 1.6,
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },
  timeIcon: {
    fontSize: '1rem',
  },
}));

export const TimeStamp: React.VFC<Props> = ({ label, time, formatter }) => {
  const classes = useStyles();
  return (
    <div className={classes.timeRow}>
      <Typography variant={'overline'}>{label}:</Typography>
      <AccessTime className={classes.timeIcon} />
      <Typography component={'div'} variant={'overline'}>
        {formatter(time)}
      </Typography>
    </div>
  );
};
