import React, { Fragment } from 'react';
import { CardListItem } from 'components/organisms/lists/cardListItem';

type Props<T> = {
  items: Array<T>;
  keyFunc: (item: T) => string | number;
  titleFunc: (item: T) => string;
  bodyFunc: (item: T) => string;
  linkFunc?: (item: T) => string;
  createTimeFunc?: (item: T) => string;
  updateTimeFunc?: (item: T) => string;
  emptyText?: string;
};

export const CardList = <T,>({
  items,
  keyFunc,
  titleFunc,
  bodyFunc,
  linkFunc,  
  createTimeFunc,
  updateTimeFunc,
  emptyText,
}: Props<T>): React.ReactElement => {
  if (items.length == 0) return emptyText ? <Fragment /> : <div>{emptyText}</div>;
  return (
    <>
      {items.map(item => (
        <CardListItem 
          key={keyFunc(item)}
          title={titleFunc(item)}
          body={bodyFunc(item)}
          link={linkFunc && linkFunc(item)}
          createdAt={createTimeFunc && createTimeFunc(item)}
          updatedAt={updateTimeFunc && updateTimeFunc(item)}
        />
      ))}
    </>
  );
};
